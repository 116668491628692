import React from "react";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function HelpDialogContent() {
  const { navConfig } = useSelector((state) => state.navConfig);
  const { faqLink } = navConfig.help;
  return (
    <Box sx={{ mb: "2em" }}>
      <Typography>
        Please click&nbsp;
        <a
          target="_blank"
          href={`${process.env.PUBLIC_URL}/resources/NJ-CRC-INDUSTRY-TM.pdf`}
          rel="noreferrer"
        >
          here to view the training material
        </a>
      </Typography>
      <Typography sx={{ textAlign: "center", textDecoration: "underline" }}>
        <a href={faqLink} target="_blank" rel="noreferrer">
          Click Here for Help/FAQ
        </a>
      </Typography>
    </Box>
  );
}

export default HelpDialogContent;
