import React from "react";
import Typography from "@mui/material/Typography";

function GettingStartedContent() {
  return (
    <Typography>
      <strong>
        Welcome to New Jersey Cannabis Regulatory Commission’s registration and
        licensing system powered by NIC Licensing Solutions.{" "}
      </strong>
      <br />
      From this platform, business operators can apply, upload paperwork, submit
      payments, and manage applications on behalf of multiple entities.
      Applications in process are saved and can be finished at a later time.
      Instructions for completing and submitting applications are available
      here.
      <br />
      If you need additional help, please click on the Help/FAQ icon so we may
      better suit your needs.
      <br />
      <a href="https://appengine.egov.com/apps/nj/crc/licensinghelp">
        Help/FAQ{" "}
      </a>
      <br />
      Please note that while the application requirements will remain the same
      under the rules, the user experience may differ slightly over the course
      of time. This is because the CRC will be continuously improving the
      application portal by incorporating actual user experience feedback to add
      additional guidance where necessary or fix unforeseen technical problems
      that may arise.
    </Typography>
  );
}
export default GettingStartedContent;
